import { useEffect, useState } from 'react'
import EndpointInfo from './components/EndpointInfo/EndpointInfo'
import useFetch from './hooks/useFetch'
import useGetParams from './hooks/useGetParams'
import services from './services/services'
import * as Styles from './styles'
import Tippy from '@tippyjs/react'
import { AiOutlineLink, AiOutlineMenu } from 'react-icons/ai'
import EndpointList from './components/EndpointList/EndpointList'
import Loading from './components/Loading/Loading'
import { MdDataObject } from 'react-icons/md'
import ProjectEnvironmentModal from './components/ProjectEnvironmentModal/ProjectEnvironmentModal'

function App() {
  const projectSlug = useGetParams()
  const { data, error, loading } = useFetch(() => services.getDocs(projectSlug), { doInitialRequest: true })
  const [selectedEndpoint, setSelectedEndpoint] = useState(null)
  const [showMenu, setShowMenu] = useState(true)
  const [endpointsListWidth, setEndointsListWidth] = useState(365)
  const [showProjectEnvironmentModal, setShowProjectEnvironmentModal] = useState(false)

  const handleShowMenu = () => {
    if (!showMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    setShowMenu(!showMenu)
  }

  const handleClickHost = e => {
    navigator.clipboard.writeText(`https://api.mockfly.dev/mocks/${projectSlug}`)
  }

  const onSelectEndpoint = endpoint => {
    setSelectedEndpoint(endpoint)
    setShowMenu(false)
  }

  useEffect(() => {
    if (data) setSelectedEndpoint(data?.endpoints?.[0])
  }, [data])

  if (error) return 'project not found'

  return (
    <div>
      <Loading show={loading}>
        <Styles.MainContainer>
          <Styles.Header>
            <div>
              <img src="/logos/mockfly_mini.svg" alt="mockfly logo" />
            </div>
            <div>
              <Tippy content={`Copy https://api.mockfly.dev/mocks/${projectSlug}`}>
                <button onClick={handleClickHost}>
                  <AiOutlineLink size={24} />
                </button>
              </Tippy>
              <Tippy content="View endpoints menu">
                <button id="mobileMenu" onClick={handleShowMenu}>
                  <AiOutlineMenu size={24} />
                </button>
              </Tippy>
              <Tippy content="Project environment variables">
                <button onClick={() => setShowProjectEnvironmentModal(true)}>
                  <MdDataObject size={16} />
                </button>
              </Tippy>
            </div>
          </Styles.Header>
          <EndpointList
            show={showMenu}
            endpoints={data?.endpoints || []}
            onSelectEndpoint={onSelectEndpoint}
            selectedEndpoint={selectedEndpoint?._id}
            onCloseMenu={() => setShowMenu(false)}
            onResize={setEndointsListWidth}
            projectName={data?.project?.name}
            folders={data?.folders || []}
          />
          {selectedEndpoint && <EndpointInfo info={selectedEndpoint} endpointsListWidth={endpointsListWidth} />}
        </Styles.MainContainer>
      </Loading>
      <ProjectEnvironmentModal
        show={showProjectEnvironmentModal}
        onClose={() => setShowProjectEnvironmentModal(false)}
        projectEnvironmentVariables={data?.environment}
      />
    </div>
  )
}

export default App
