import { useEffect, useState } from 'react'
import * as Styles from './styles'
import EndpointItem from './EndpointItem'
import EndpointListHeader from './EndpointListHeader/EndpointListHeader'
import Tippy from '@tippyjs/react'
import { Resizable } from 're-resizable'
import { Tree, MultiBackend, getBackendOptions, DndProvider } from '@minoru/react-dnd-treeview'
import Folder from './Folder/Folder'

const EndpointList = ({ endpoints, selectedEndpoint, onSelectEndpoint, show, onResize, projectName, folders }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const handleClick = endpoint => onSelectEndpoint(endpoint)

  const [foldersOpened, setFoldersOpened] = useState(
    localStorage.getItem('foldersOpened') ? JSON.parse(localStorage.getItem('foldersOpened')) : []
  )

  const [sortableEndpoints, setSortableEndpoints] = useState([...endpoints])
  useEffect(() => {
    setSortableEndpoints(
      [
        ...folders.map(f => ({ ...f, type: 'folder', parent: 0 })),
        ...endpoints.map(e => ({
          ...e,
          type: 'endpoint',
          parent: folders.find(f => f.endpoints.includes(e._id))?._id || 0,
        })),
      ].sort((a, b) => a.position - b.position)
    )
  }, [endpoints, folders, sortableEndpoints.length])

  const handleToggleFolder = folders => {
    localStorage.setItem('foldersOpened', JSON.stringify(folders))
    setFoldersOpened(folders)
  }

  const isVisible = e => {
    if (e.type === 'folder') {
      if (searchQuery) {
        const endpointsInFolder = endpoints.filter(
          endpoint => endpoint.parent === e._id && endpoint.path.toLowerCase().includes(searchQuery.toLowerCase())
        )
        return endpointsInFolder.length > 0 || e.name.toLowerCase().includes(searchQuery.toLowerCase())
      }

      return true
    }

    if (e.type === 'endpoint') {
      return e.path.toLowerCase().includes(searchQuery.toLowerCase())
    }

    return false
  }

  return (
    <>
      <Resizable
        className="resizable-menu"
        defaultSize={{ width: 365 }}
        minWidth={365}
        maxWidth={600}
        onResize={(e, direction, ref) => {
          onResize(ref.style.width)
        }}
      >
        <Styles.EndpointList show={show}>
          <Styles.ProjectTitleContainer>
            <Tippy content={projectName}>
              <Styles.ProjectTitle>{projectName}</Styles.ProjectTitle>
            </Tippy>
          </Styles.ProjectTitleContainer>
          <EndpointListHeader onSearch={e => setSearchQuery(e.target.value)} />
          <Styles.EndpointContent>
            <Styles.TreeContainer>
              <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <Tree
                  rootId={0}
                  onChangeOpen={handleToggleFolder}
                  tree={sortableEndpoints.map(endpoint => ({
                    ...endpoint,
                    id: endpoint._id,
                    droppable: endpoint.type === 'folder',
                    isVisible: isVisible(endpoint),
                  }))}
                  initialOpen={foldersOpened}
                  render={(node, { isOpen, onToggle, depth }) => {
                    if (!node.isVisible) return null

                    if (node.type === 'folder') {
                      return <Folder onToggle={onToggle} isOpen={foldersOpened.includes(node._id)} name={node.name} />
                    }

                    if (node.type === 'endpoint') {
                      return (
                        <EndpointItem
                          endpoint={node}
                          handleClick={handleClick}
                          selectedEndpoint={selectedEndpoint}
                          key={node._id}
                          depth={depth}
                        />
                      )
                    }
                  }}
                  sort={false}
                />
              </DndProvider>
            </Styles.TreeContainer>
          </Styles.EndpointContent>
        </Styles.EndpointList>
      </Resizable>
    </>
  )
}
export default EndpointList
