import * as Styles from './styles'

const URLParamsSection = ({ path }) => {
  const params = path.match(/:\w+/g)

  return (
    <Styles.Container>
      <h3>URL Params</h3>
      {params?.length ? (
        <ul>
          {params?.map((param, index) => (
            <li key={index}>
              <span>{param}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No URL params</p>
      )}
    </Styles.Container>
  )
}

export default URLParamsSection
