import * as Styles from './styles'
import Modal from '../Modal'
import { Editor } from '@monaco-editor/react'
import { useState } from 'react'
import { useEffect } from 'react'

const ProjectEnvironmentModal = ({ show, onClose, projectEnvironmentVariables }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (projectEnvironmentVariables) {
      setValue(JSON.stringify(projectEnvironmentVariables, null, 2))
    }
  }, [projectEnvironmentVariables, show])

  return (
    <Modal title="Project environment variables" show={show} onClose={onClose} size="l">
      <Styles.EditorContainer>
        <Editor
          className="editor-container"
          height="300px"
          language="json"
          value={value}
          theme="vs-dark"
          options={{
            minimap: { enabled: false },
            readOnly: true,
          }}
        />
      </Styles.EditorContainer>
    </Modal>
  )
}

export default ProjectEnvironmentModal
