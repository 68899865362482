import * as Styles from './styles'

const QueryParamsSection = ({ path }) => {
  const searchParams = path?.split('?')[1]
  const params = new URLSearchParams(searchParams)
  const paramsEntries = Array.from(params.entries())

  return (
    <Styles.Container>
      <h3>Query Params</h3>
      {paramsEntries?.length ? (
        <ul>
          {paramsEntries?.map(([param, value], index) => (
            <li key={index}>
              <span>
                {param}: {value}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No query params</p>
      )}
    </Styles.Container>
  )
}

export default QueryParamsSection
