import { AiFillCaretDown, AiFillCaretRight, AiFillFolder } from 'react-icons/ai'
import * as Styles from './styles'
import { useRef, useState } from 'react'

const Folder = ({ onToggle, isOpen, name }) => {
  const inputRef = useRef(null)
  const [folderName, setFolderName] = useState(name)

  useState(() => {
    setFolderName(name)
  }, [name])

  return (
    <>
      <Styles.FolderContainer onClick={onToggle} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {isOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}
        <AiFillFolder size={16} />
        <Styles.InputName readOnly ref={inputRef} value={folderName}></Styles.InputName>
      </Styles.FolderContainer>
    </>
  )
}

export default Folder
