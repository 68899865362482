import styled, { css } from 'styled-components'

export const FolderContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 40px;
  user-select: none;

  ${props =>
    props.depth &&
    css`
      margin-left: 32px;
    `}

  &:hover {
    background: rgba(72, 207, 173, 0.25);
  }

  ${props =>
    props.active &&
    css`
      background: rgba(72, 207, 173, 0.25);
    `}

  ${props =>
    props.disabled &&
    css`
      background: rgba(72, 207, 173, 0.1);
      &:hover {
        background: rgba(72, 207, 173, 0.1);
      }
      cursor: not-allowed;
    `}
`

export const InputName = styled.input`
  background-color: transparent;
  color: white;
  border: none;
  font-size: 14px;
  cursor: pointer;

  ${props =>
    !props.isEditing &&
    css`
      &:focus {
        border: none;
        outline: none;
      }
    `}

  ${props =>
    props.isEditing &&
    css`
      &:focus {
        outline: 1px solid rgba(72, 207, 173, 0.25);
        border-radius: 14px;
        padding: 4px 8px;
      }
    `}
`
