import { useState } from 'react'
import MethodBadge from '../MethodBadge/MethodBadge'
import * as Styles from './styles'
import Tabs from '../Tabs/Tabs'
import { Editor } from '@monaco-editor/react'
import HeadersSection from '../HeadersSection/HeadersSection'
import Tippy from '@tippyjs/react'
import { Graph } from 'jsoncrack-react'
import URLParamsSection from '../URLParamsSection/URLParamsSection'
import QueryParamsSection from '../QueryParamsSection/QueryParamsSection'

const EndpointInfo = ({ info, endpointsListWidth }) => {
  const [tabSelected, setTabSelected] = useState('body')

  const isJSON = body => {
    if (typeof body === 'object' && body !== null) {
      return true
    }

    if (typeof body === 'string') {
      try {
        JSON.parse(body)
        return true
      } catch (error) {
        return false
      }
    }

    return false
  }

  const body = isJSON(info?.defaultResponse?.body)
    ? JSON.stringify(info?.defaultResponse?.body, null, 2)
    : info?.defaultResponse?.body

  const isServingFile = () => {
    const contentTypeHeader = info?.headers?.find(header => header.key.toLowerCase() === 'content-type')

    return ['application/pdf', 'text/csv'].includes(contentTypeHeader?.value)
  }

  const getServingFileMessage = () => {
    const contentTypeHeader = info?.headers?.find(header => header?.key?.toLowerCase() === 'content-type')

    if (contentTypeHeader?.value === 'application/pdf') {
      return '//Serving PDF file. Checkout headers tab.'
    }

    if (contentTypeHeader?.value === 'text/csv') {
      return '//Serving CSV file. Checkout headers tab.'
    }
  }

  return (
    <Styles.Container endpointsListWidth={endpointsListWidth}>
      <Styles.MethodAndPathContainer>
        <MethodBadge method={info.method}>{info.method}</MethodBadge>
        <Tippy content={info.path}>
          <p>{info.path}</p>
        </Tippy>
      </Styles.MethodAndPathContainer>
      <p>{info.description}</p>
      <Styles.TabsAndEndpointActionsContainer>
        <Tabs
          elements={[
            { label: 'Response body', id: 'body' },
            { label: 'URL params', id: 'urlParams' },
            { label: 'Query params', id: 'queryParams' },
            { label: 'Headers', id: 'headers' },
            { label: 'Request body', id: 'requestBody' },
            { label: 'Graph', id: 'graph' },
          ]}
          value={tabSelected}
          onChange={element => setTabSelected(element.id)}
        />
      </Styles.TabsAndEndpointActionsContainer>
      <Styles.TabContentContainer>
        <Styles.TabsContent>
          {tabSelected === 'body' && (
            <Styles.EditorContainer>
              <Editor
                className="editor-container"
                height="calc(100vh - 270px)"
                language={isJSON(info?.defaultResponse?.body) ? 'json' : 'xml'}
                value={isServingFile() ? getServingFileMessage() : body}
                theme="vs-dark"
                options={{
                  minimap: { enabled: false },
                  readOnly: true,
                }}
              />
            </Styles.EditorContainer>
          )}
          {tabSelected === 'headers' && <HeadersSection headers={info.headers} />}
          {tabSelected === 'requestBody' && (
            <Styles.EditorContainer>
              <Editor
                className="editor-container"
                height="calc(100vh - 270px)"
                language="json"
                value={JSON.stringify(info?.bodyExample, null, 2)}
                theme="vs-dark"
                options={{
                  minimap: { enabled: false },
                  readOnly: true,
                }}
              />
            </Styles.EditorContainer>
          )}
          {tabSelected === 'graph' && (
            <div>
              <Graph
                style={{
                  height: 'calc(100vh - 270px)',
                  borderBottomLeftRadius: '40px',
                  borderBottomRightRadius: '40px',
                }}
                json={JSON.stringify(info?.defaultResponse.body, null, 2)}
              />
            </div>
          )}
          {tabSelected === 'urlParams' && <URLParamsSection path={info?.path} />}
          {tabSelected === 'queryParams' && <QueryParamsSection path={info?.path} />}
        </Styles.TabsContent>
      </Styles.TabContentContainer>
    </Styles.Container>
  )
}

export default EndpointInfo
