import styled, { css } from 'styled-components'

export const ModalOverlay = styled.div`
  background-color: transparent;
  z-index: 1000;
  transition: background-color 0.3s;
  ${props =>
    props.show &&
    css`
      position: fixed;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(10px);
    `}
`

export const ModalContainer = styled.div`
  background-color: #101010;
  color: #ffffff;
  opacity: 1;
  border-radius: 40px;
  border-color: 1px solid black;
  margin: auto;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in 0.5s;
  min-width: 70%;
  max-height: 80%;
  overflow: ${props => props.overflow || 'auto'};
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.14);
  padding: 40px;

  ${props =>
    props.light &&
    css`
      background-color: #48cfad;
      color: black;
    `}

  ${props =>
    props.show &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};

  ${props =>
    props.size === 's' &&
    css`
      min-width: 20%;
      max-width: 20%;
    `};

  ${props =>
    props.size === 'm' &&
    css`
      min-width: 436px;
      max-width: 436px;
    `};

  ${props =>
    props.size === 'l' &&
    css`
      min-width: 640px;
      max-width: 640px;
    `};

  ${props =>
    props.size === 'xl' &&
    css`
      min-width: 70%;
      max-width: 70%;
    `};

  @media screen and (max-width: 831px) {
    min-width: 52%;
    max-width: 52%;
  }
`

export const ModalContent = styled.div`
  margin-top: 21px;
`

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  svg {
    &:hover {
      cursor: pointer;
    }
    font-size: 2rem;
  }
`

export const ModalHeader = styled.div`
  background-color: #101010;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${props =>
    props.light &&
    css`
      background-color: #48cfad;
    `}

  svg {
    :hover {
      color: #8b949f;
      cursor: pointer;
    }
  }
`

export const WarningIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

export const ModalTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  text-transform: uppercase;
  font-weight: 800;

  ${props =>
    props.warning &&
    css`
      text-align: center;
    `}
`

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
`
