import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import * as Styles from './styles'
import { AiOutlineClose } from 'react-icons/ai'
import { CiWarning } from 'react-icons/ci'

const Modal = ({ children, show, onClose, title, actions, size, light, warning, overflow }) => {
  const [delaying, setDelaying] = useState(false)
  const [initShow, setInitShow] = useState(false)

  useEffect(() => {
    const close = e => {
      if (show && e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose, show])

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
      setTimeout(() => {
        setInitShow(true)
        setDelaying(true)
      })
    } else {
      document.body.style.overflow = 'unset'
      setTimeout(() => {
        setInitShow(false)
        setTimeout(() => {
          setDelaying(false)
        }, 300)
      })
    }
  }, [show])

  const handleClose = e => {
    if (e.target.id === 'modalOverLay') {
      onClose()
    }
  }

  if (show) {
    return ReactDOM.createPortal(
      <Styles.ModalOverlay
        show={initShow || delaying}
        id="modalOverLay"
        data-testid="modalOverlay"
        onClick={handleClose}
      >
        <Styles.ModalContainer show={initShow} size={size} light={light} overflow={overflow}>
          <Styles.ModalHeader light={light}>
            <AiOutlineClose onClick={onClose} size={22} />
          </Styles.ModalHeader>
          <Styles.ModalContent>
            {warning && (
              <Styles.WarningIcon>
                <CiWarning size={40} />
              </Styles.WarningIcon>
            )}
            {title && <Styles.ModalTitle warning={warning}>{title}</Styles.ModalTitle>}
            {children}
            {actions && <Styles.ModalActions>{actions}</Styles.ModalActions>}
          </Styles.ModalContent>
        </Styles.ModalContainer>
      </Styles.ModalOverlay>,
      document.getElementById('modal')
    )
  }
}

export default Modal
