import styled, { css } from 'styled-components'

export const EndpointList = styled.div`
  display: flex;
  transition: all 0.2s ease-in-out;
  list-style: none;
  flex-direction: column;
  height: calc(100vh);

  @media screen and (max-width: 800px) {
    display: none;
    background-color: black;
    ${props =>
      props.show &&
      css`
        width: 100%;
        display: block;
        position: absolute;
        z-index: 2;
        height: auto;
      `}
  }
`

export const EndpointItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 600;
  padding: 12px 16px;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 40px;

  & > span:first-child  {
    font-size: 14px;
    width: 100%;
    padding-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-child {
    padding-top: 16px;
  }

  &:hover {
    background: rgba(72, 207, 173, 0.25);
  }

  ${props =>
    props.active &&
    css`
      background: rgba(72, 207, 173, 0.25);
    `}

  ${props =>
    props.depth &&
    css`
      margin-left: 32px;
    `}
`

export const EndpointContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-left: 8px;
  height: calc(100vh - 85px);
  overflow-y: auto;

  -webkit-scrollbar-thumb {
    color: red;
  }

  @media screen and (max-width: 800px) {
    height: calc(100vh - 212px);
  }
`

export const Host = styled.p`
  margin: 0;
  font-size: 11px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px;
  cursor: pointer;
`

export const ProjectTitleContainer = styled.div`
  display: flex;
  padding: 16px 16px;
  justify-content: space-between;
  align-items: center;
`

export const ProjectTitle = styled.h2`
  font-size: 20px;
  text-transform: uppercase;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
`

export const Saved = styled.span`
  display: flex;
  align-items: center;
  color: #8b949f;
  font-size: 12px;
  margin-right: 8px;

  svg {
    color: #a0d467;
    padding: 0px 4px;
  }
`

export const Saving = styled.span`
  display: flex;
  align-items: center;
  color: #8b949f;
  font-size: 12px;
  margin-right: 8px;

  svg {
    padding: 0px 4px;
  }
`

export const TreeContainer = styled.div`
  padding-top: 16px;

  ul {
    list-style: none;
    padding-left: 0;
  }
`

export const EndpointNameContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-size: 14px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
