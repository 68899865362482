import styled, { css } from 'styled-components'

const MethodBadge = styled.span`
  display: flex;
  padding: 2px 5px;
  border-radius: 4px;
  min-width: 58px;
  justify-content: center;
  font-size: 14px;
  color: black;
  border-radius: 20px;

  ${props =>
    props.method === 'GET' &&
    css`
      background-color: #a0d467;
    `}

  ${props =>
    props.method === 'POST' &&
    css`
      background-color: #4ec1e8;
    `}

    ${props =>
    props.method === 'PUT' &&
    css`
      background-color: #ffcf54;
    `}

    ${props =>
    props.method === 'PATCH' &&
    css`
      background-color: #ac92ed;
    `}

    ${props =>
    props.method === 'DELETE' &&
    css`
      background-color: #ec5664;
    `}


    ${props =>
    props.method === 'OPTIONS' &&
    css`
      background-color: #48cfad;
    `}

    ${props =>
    props.method === 'HEAD' &&
    css`
      background-color: #ec88c1;
    `}
`

export default MethodBadge
