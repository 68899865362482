import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: ${props => (props.height ? `${props.height}px` : `calc(100vh - 150px)`)};
  svg {
    animation: ${rotate} 1s linear infinite;
    width: 60px;
    height: 60px;
    color: ${props => (props.color ? props.color : '#3c4c61')};
    path {
      stroke-width: 1px;
    }
  }
`
