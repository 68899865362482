import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(95% - 365px);
  width: ${props => `calc(95% - ${props.endpointsListWidth})`};
  max-width: ${props => `calc(95% - ${props.endpointsListWidth})`};
  margin-top: 32px;
  padding-left: 16px;

  @media screen and (max-width: 800px) {
    margin-top: 0px;
    padding: 8px;
    width: 100%;
    max-width: calc(100vw - 16px);
  }
`

export const MethodAndPathContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    margin-top: 16px;

    & > span {
      width: 100%;
      max-width: 300px;
    }

    & > p {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
`

export const TabsAndEndpointActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  & > div:last-child {
    a,
    button {
      position: relative;
      top: -10px;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-right: 0px;

    & > div:last-child {
      a,
      button {
        position: relative;
        top: auto;
        margin-top: 16px;
      }
    }
  }
`

export const TabContentContainer = styled.div`
  padding-right: 16px;

  @media screen and (max-width: 800px) {
    padding-right: 0px;
  }
`

export const TabsContent = styled.div`
  height: 100%;
  border: 2px solid #48cfad;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 16px;
  }
`

export const EditorContainer = styled.div`
  position: relative;
  padding-left: 4px;
`
