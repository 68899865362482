import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import * as Styles from './styles'

const Loading = ({ show, children, color, height }) => {
  if (show) {
    return (
      <Styles.LoadingContainer color={color} height={height}>
        <AiOutlineLoading3Quarters />
      </Styles.LoadingContainer>
    )
  }
  return children
}

export default Loading
