import Tippy from '@tippyjs/react'
import MethodBadge from '../../components/MethodBadge/MethodBadge'
import * as Styles from './styles'
import { AiFillLock } from 'react-icons/ai'

const EndpointItem = ({ endpoint, selectedEndpoint, handleClick, depth }) => {
  const onContextMenu = e => {
    e.preventDefault()
    handleClick(endpoint)
  }

  if (endpoint.path.length > 20) {
    return (
      <>
        <Tippy content={endpoint.path} maxWidth="900px">
          <Styles.EndpointItem
            depth={depth}
            disabled={endpoint.disabledForExceedingTheLimitOfEndpoints}
            onContextMenu={onContextMenu}
            onClick={() => !endpoint?.disabledForExceedingTheLimitOfEndpoints && handleClick(endpoint)}
            active={selectedEndpoint === endpoint._id}
          >
            <Styles.EndpointNameContainer>
              {endpoint?.disabledForExceedingTheLimitOfEndpoints && <AiFillLock />}
              <span>{endpoint.path}</span>
            </Styles.EndpointNameContainer>
            <MethodBadge method={endpoint.method}>{endpoint.method}</MethodBadge>
          </Styles.EndpointItem>
        </Tippy>
      </>
    )
  }

  return (
    <>
      <Styles.EndpointItem
        depth={depth}
        onContextMenu={onContextMenu}
        disabled={endpoint.disabledForExceedingTheLimitOfEndpoints}
        onClick={() => !endpoint?.disabledForExceedingTheLimitOfEndpoints && handleClick(endpoint)}
        active={selectedEndpoint === endpoint._id}
      >
        <Styles.EndpointNameContainer>
          {endpoint?.disabledForExceedingTheLimitOfEndpoints && <AiFillLock />}
          <span>{endpoint.path}</span>
        </Styles.EndpointNameContainer>
        <MethodBadge method={endpoint.method}>{endpoint.method}</MethodBadge>
      </Styles.EndpointItem>
    </>
  )
}

export default EndpointItem
